const data = {
  universities: [
    {
      image: "/images/universities-pic/southbank-university.jpeg",
      location: "United Kingdom, London",
      name: "London South Bank University",
      rank: "801 - 1000 (QS World University Ranking)",
      about:
        "Located minutes from the center of London, LSBU has been preparing and developing students for their working lives for over 125 years. Here at LSBU, we put our students first and almost 2,000 International students from over 130 countries have chosen this University.",
      facebook: "",
      twitter: "",
      linkedin: "",
      website: "",
      loc: "UK",
    },
    {
      image: "/images/universities-pic/east-london-university.jpeg",
      location: "United Kingdom, London",
      name: "University of East London",
      rank: "801 - 1000 (QS World University Ranking)",
      about:
        "An internationally recognized UK university with 17,000 students from 135 countries enrolled. As a careers-led university, it is dedicated to supporting international students to thrive in a constantly changing world and competitive jobs market.",
      facebook: "",
      twitter: "",
      linkedin: "",
      website: "",
      loc: "UK",
    },
    {
      image: "/images/universities-pic/richmond-university.jpeg",
      location: "United Kingdom, London",
      name: "Richmond American University",
      rank: "-",
      about:
        "We are delighted to announce that Richmond is one of the top five London universities for overall satisfaction levels in the National Student Survey. Richmond is the true Liberal Arts University. Study with university and receive both a UK & US degree.",
      facebook: "",
      twitter: "",
      linkedin: "",
      website: "",
      loc: "UK",
    },
    {
      image: "/images/universities-pic/bpp-university.webp",
      location: "United Kingdom, London",
      name: "BPP University",
      rank: "120 (Webometrics Ranking)",
      about:
        "BPP University is a leading UK institution with a strong commitment to the development of professional skills. Law students here have access to simulation courtrooms, as well as an experienced and knowledgeable career services team that",
      facebook: "",
      twitter: "",
      linkedin: "",
      website: "",
      loc: "UK",
    },
    {
      image: "/images/universities-pic/greenwich-university.jpeg",
      location: "United Kingdom, London",
      name: "University of Greenwich",
      rank: "751 - 800 (QS World University Ranking)",
      about:
        "Well-known for its high teaching quality, research excellence, diversity of students, its beautiful, historic campuses in south-east London and Kent, and increased student satisfaction. The University is proud of its diverse student body. People from more than 140 countries choose to study at Greenwich",
      facebook: "",
      twitter: "",
      linkedin: "",
      website: "",
      loc: "UK",
    },
    {
      image: "/images/universities-pic/queensland-university.jpeg",
      location: "Australia, Brisbane, Sydney, Melbourne",
      name: "Central Queensland University",
      rank: "651 - 700 (QS World University Ranking)",
      about:
        "CQUniversity has been placed well above the Australian university average in overall satisfaction, and arrival, learning, and support experience in the 2021 International Student Barometer.",
      facebook: "",
      twitter: "",
      linkedin: "",
      website: "",
      loc: "Australia",
    },
    {
      image: "/images/universities-pic/uts-university.jpeg",
      location: "Australia, Sydney, Melbourne",
      name: "University of Technology Sydney",
      rank: "137 (QS World University Ranking)",
      about:
        "UTS ranked 1st in Australia and 8th globally in the Times Higher Education Young University Rankings 2022, a comprehensive system the agency designed for universities under 50 years old with indicators linked to industry innovation as well as academic excellence.",
      facebook: "",
      twitter: "",
      linkedin: "",
      website: "",
      loc: "Australia",
    },
    {
      image: "https://aimseducation.co.uk/wp-content/uploads/2023/11/Northumbria-University-Newcastle-800x420-1.jpg",
      location: "United Kingdom, London",
      name: "Northumbria University London",
      rank: "550 (QS World University Ranking)",
      about:
        "Northumbria University is the top university of world. It has an international research reputation, is rated highly by local and international students,",
      facebook: "",
      twitter: "",
      linkedin: "",
      website: "",
      loc: "Australia",
    },
    {
      image: "https://theukstudyexpert.com/wp-content/uploads/2023/11/StudentsGroveHouse047.jpg",
      location: "Oshawa, Canada",
      name: "Ontario Tech University",
      rank: "-",
      about:
        "The University of Ontario Institute of Technology, also known as Ontario Tech University or Ontario Tech, is a public research university located in Oshawa, Ontario, Canada. Ontario Tech's main campus is located on approximately 400 acres of land in the northern part of Oshawa.",
      facebook: "",
      twitter: "",
      linkedin: "",
      website: "",
      loc: "Canada",
    },
    {
      image: "/images/universities-pic/vancouver-university.jpeg",
      location: "United Kingdom,London",
      name: "Roehampton University",
      rank: "1001-1200 (QS World University Ranking)",
      about:
        "At VIU, you matter, and you will find support every step of the way. We offer a broad network of student supports to make sure you’re set up for success even if things get tough. Along the way, you’ll learn how you can make a difference in your job, your community and maybe even the world. Find a program to match your interests as you prepare for a rewarding career and a fulfilling life.",
      facebook: "",
      twitter: "",
      linkedin: "",
      website: "",
      loc: "Canada",
    },
    {
      image: "/images/universities-pic/cape-university.avif",
      location: "Sydney, Canada",
      name: "Cape Breton University",
      rank: "-",
      about:
        "A diverse campus and welcoming environment are waiting for you at Cape Breton University. Here, you’ll get more than just a world-class education. You’ll build life-long friendships, gain global perspectives and experience the vibrant culture of Cape Breton Island.",
      facebook: "",
      twitter: "",
      linkedin: "",
      website: "",
      loc: "Canada",
    },
    {
      image: "/images/universities-pic/saint-university.jpeg",
      location: "St. Louis, Missouri, US",
      name: "Saint Louis University",
      rank: "601-650 (QS World University Ranking)",
      about:
        "Saint Louis University is a Catholic, Jesuit institution with campuses in St. Louis, Missouri, United States, and Madrid, Spain. SLU values academic excellence, life-changing research, compassionate health care, and a strong commitment to faith and service.",
      facebook: "",
      twitter: "",
      linkedin: "",
      website: "",
      loc: "US",
    },
    {
      image: "/images/universities-pic/massachusetts-university.jpeg",
      location: "Boston, US",
      name: "University of Massachusetts Boston",
      rank: "701-750 (QS World University Ranking)",
      about:
        "As Boston’s only public business school, the University of Massachusetts Boston (UMass Boston) offers graduate business degree and certificate programs to help you take your “next steps”.  AACSB accredited and affordable, UMass Boston’s programs are convenient and flexible for today’s active students.",
      facebook: "",
      twitter: "",
      linkedin: "",
      website: "",
      loc: "US",
    },
  ],
};

export default data;
